import React, { useState, useEffect } from "react"
import { Row, Col, Button } from "reactstrap"
// import { Form, Input, FormGroup, Label } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { PropagateLoader } from "react-spinners"
import { toast } from "react-toastify"
import Captcha from "../../../components/common/captcha"
import { useAuth0 } from "@auth0/auth0-react"
import _ from "lodash"
import { isValidEmail } from "../../../utils/common"
import { fetch_post } from "../../../utils/fetch"
import * as Sentry from "@sentry/gatsby"

export default function TakedownCreate(props) {
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(false)
  const [markercode, setMarkercode] = useState("")
  const [inputCode, setInputCode] = useState("")
  const [form, setForm] = useState({})
  const [formCopy, setFormCopy] = useState({})
  const [showFullForm, setShowFullForm] = useState(false)
  const [modalInfo, setModalInfo] = useState({ show: false, header: "", content: "" })
  const copyRightHolderOptions = [
    { id: "1", val: "Yes. I am the copyright holder" },
    { id: "2", val: "Yes, I am authorized to act on the copyright owner’s behalf" },
    { id: "3", val: "No" },
  ]
  const claimContentOnCodelinaroOptions = [
    { id: "1", val: "Yes" },
    { id: "2", val: "No" },
  ]

  const technoligicalMeasureOptions = [
    { id: "1", val: "Yes" },
    { id: "2", val: "No" },
  ]

  const workUnderOpenSourceOptions = [
    { id: "1", val: "Yes" },
    { id: "2", val: "No" },
  ]

  const solutionForInfringementOptions = [
    { id: "1", val: "Repository can be made private" },
    { id: "2", val: "Reported content must be removed" },
    { id: "3", val: "Other Change" },
  ]

  const get_description_format = (name, isdrp = false, drpValueList = null) => {
    var question = document.getElementById(`${name}_label`).innerText
    var val = form[name]
    if (isdrp) {
      let searchRes = drpValueList.filter(function (item) {
        return val === item.id
      })
      if (searchRes.length > 0) val = searchRes[0].val
    }
    return `Q). ${question}\nA). ${val}\n--------------------------------\n`
  }

  const generate_description_text = () => {
    var ret = ""
    ret += get_description_format("name")
    ret += get_description_format("emailaddress")
    ret += get_description_format("copyRightHolderOption", true, copyRightHolderOptions)
    ret += get_description_format(
      "claimContentOnCodelinaroOption",
      true,
      claimContentOnCodelinaroOptions
    )
    ret += get_description_format("technoligicalMeasureOption", true, technoligicalMeasureOptions)
    ret += get_description_format("workUnderOpenSourceOption", true, workUnderOpenSourceOptions)
    ret += get_description_format(
      "solutionForInfringementOption",
      true,
      solutionForInfringementOptions
    )
    ret += get_description_format("copyRightNature")
    ret += get_description_format("detailDescriptionOfOriginal")
    ret += get_description_format("detailDescriptionOfOriginal")
    ret += get_description_format("takeDownUrls")
    ret += get_description_format("infringingFiles")
    ret += get_description_format("infringerContactInformation")
    ret += get_description_format("isContentAuthorised")
    ret += get_description_format("isInformationAccurate")
    ret += get_description_format("isFaireUseConsidered")
    ret += get_description_format("hasReadCodelinaroTakedownNotice")
    ret += get_description_format("communicationAddress")
    ret += get_description_format("fullName")
    return ret
  }

  const clearForm = () => {
    return {
      name: "",
      emailaddress: "",
      copyRightHolderOption: "0",
      claimContentOnCodelinaroOption: "0",
      technoligicalMeasureOption: "0",
      workUnderOpenSourceOption: "0",
      solutionForInfringementOption: "0",
      copyRightNature: "",
      detailDescriptionOfOriginal: "",
      takeDownUrls: "",
      infringingFiles: "",
      infringerContactInformation: "",
      isContentAuthorised: false,
      isInformationAccurate: false,
      isFaireUseConsidered: false,
      hasReadCodelinaroTakedownNotice: false,
      communicationAddress: "",
      fullName: "",
    }
  }

  const validate_form = () => {
    if (_.isEmpty(form.name)) return "Name is required"
    else if (_.isEmpty(form.emailaddress)) return "Email is required"
    else if (!isValidEmail(form.emailaddress)) return "Email address should be valid"
    else if (_.isEmpty(form.copyRightHolderOption) || form.copyRightHolderOption === "0")
      return "Copy right holder option are requierd"
    else if (
      _.isEmpty(form.claimContentOnCodelinaroOption) ||
      form.claimContentOnCodelinaroOption === "0"
    )
      return "All fields are required"
    else if (_.isEmpty(form.technoligicalMeasureOption) || form.technoligicalMeasureOption === "0")
      return "All fields are required"
    else if (_.isEmpty(form.workUnderOpenSourceOption) || form.workUnderOpenSourceOption === "0")
      return "All fields are required"
    else if (_.isEmpty(form.copyRightNature)) return "All fields are required"
    else if (_.isEmpty(form.detailDescriptionOfOriginal)) return "All fields are required"
    else if (_.isEmpty(form.takeDownUrls)) return "All fields are required"
    else if (_.isEmpty(form.infringingFiles)) return "All fields are required"
    else if (_.isEmpty(form.infringerContactInformation)) return "All fields are required"
    else if (!form.isContentAuthorised) return "All fields are required"
    else if (!form.isInformationAccurate) return "All fields are required"
    else if (!form.isFaireUseConsidered) return "All fields are required"
    else if (!form.hasReadCodelinaroTakedownNotice) return "All fields are required"
    else if (_.isEmpty(form.communicationAddress)) return "All fields are required"
    else if (_.isEmpty(form.fullName)) return "All fields are required"
    else if (_.isEmpty(inputCode)) return "Captcha code is required"
    else if (inputCode !== markercode) return "Incorrect captch code entered"
    return null
  }

  const hande_change = e => {
    let temp = JSON.parse(JSON.stringify(form))
    temp[e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value
    setForm(temp)
    // console.log(temp)
  }

  const has_changes = () => {
    return JSON.stringify(form) !== JSON.stringify(formCopy)
  }

  const cancel_changes = () => {
    let temp = JSON.parse(JSON.stringify(clearForm()))
    setForm(temp)
    setFormCopy(temp)
  }

  const getMarkerCode = code => {
    console.log("marker code is", code)
    setMarkercode(code)
  }

  const reset_form = () => {
    var obj = clearForm()
    setForm(obj)
    setFormCopy(obj)
    setInputCode("")
  }

  const submit_form = async () => {
    var res = validate_form()
    var token = await getAccessTokenSilently()
    var description = generate_description_text()
    console.log(description)

    if (res !== null) {
      toast(`${res}`, {
        type: "error",
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    } else {
      fetch_post(`ui/support/dmca/takedown/create`, { theFormData: description }, token)
        .then(response => {
          console.log(response)
          if (response.status.code === 201) {
            toast(`${response.status.description}`, {
              type: "success",
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
            reset_form()
          }
        })
        .catch(error => {
          Sentry.captureException(error)
          console.log(`Error while creating takedown ticket from: ${error}`)
        })
    }
  }

  const open_modal = (header, content) => {
    setModalInfo({
      show: true,
      header: header,
      content: content,
    })
  }

  const close_modal = () => {
    setModalInfo({
      show: false,
      header: null,
      content: null,
    })
  }

  useEffect(() => {
    if (_.isEmpty(form)) return
    if (_.isEmpty(form.copyRightHolderOption)) return
    if (form.copyRightHolderOption === "0") {
      setShowFullForm(false)
      return
    }

    if (form.copyRightHolderOption === "3") {
      setShowFullForm(false)
      open_modal(
        "Sorry",
        "We are unable to process DMCA takedown notices unless they are submitted by the copyright owner or an agent authorized to act on behalf of the copyright owner."
      )
    } else setShowFullForm(true)
  }, [form.copyRightHolderOption])

  useEffect(() => {
    reset_form()
  }, [])

  return (
    <>
      {loading && (
        <Col
          className="d-flex justify-content-center align-items-center"
          xs={{ size: 12 }}
          sm={{ size: 4, offset: 4 }}
        >
          <PropagateLoader className="align-self-center" size={20} color={"#9c3"} loading={true} />
        </Col>
      )}
      <div>
        <p>
          If you believe someone is using your copyrighted content in an unauthorized way on GitHub
          or npm.js, please fill out the form below to submit a DMCA takedown notice to request that
          the content be changed or removed. Before you begin, please read the CodeLinaro{" "}
          <a href="https://www.codelinaro.org/faq/209813505/" target="_blank">
            DMCA Takedown Policy.
          </a>
        </p>
        <p>
          In order to prevent unnecessary processing delays, please note the following before
          submitting your notice:
        </p>
        <ul>
          <li>Don't send duplicate notices.</li>
          <li>
            If you believe multiple files or repositories are infringing the same copyrighted
            content, include links to those files or repositories in a single notice, instead of
            sending multiple notices.
          </li>
          <li>Only one point of contact from your organization should submit the notice.</li>
          <li>
            Our DMCA takedown process is intended only for copyrighted content. If the content
            you're reporting is private or personally identifying and not protected by copyright,
            please review our{" "}
            <a href="https://www.codelinaro.org/faq/209813505/" target="_blank">
              Private Information Removal Policy
            </a>
            . If the content violates a trademark you own, please review our{" "}
            <a href="https://www.codelinaro.org/faq/209813505/" target="_blank">
              Trademark Policy
            </a>
            .
          </li>
        </ul>
        If you are requesting on behalf of a government agency to remove content that has been
        declared unlawful in their local jurisdiction, please complete the following information:
        <div className="mb-3">
          <label id="name_label" className="form-label">
            Name
          </label>
          <input
            type="text"
            placeholder="Your name"
            className="form-control"
            id="name"
            name="name"
            onChange={e => hande_change(e)}
            aria-describedby="emailHelp"
            value={form.name || ""}
          />
          {/* <div id="emailHelp" className="form-text">
              We'll never share your email with anyone else.
            </div> */}
        </div>
        <div className="mb-3">
          <label id="emailaddress_label" className="form-label">
            Email Address
          </label>
          <input
            type="text"
            placeholder="Your email address"
            className="form-control"
            id="emailaddress"
            name="emailaddress"
            onChange={e => hande_change(e)}
            aria-describedby="emailHelp"
            value={form.emailaddress || ""}
          />
        </div>
        <div className="mb-3">
          <label id="copyRightHolderOption_label" className="form-label">
            Are you the copyright holder or authorized to act on the copyright owner's behalf?
          </label>
          <select
            className="form-control"
            value={form.copyRightHolderOption}
            aria-label="Default select example"
            name="copyRightHolderOption"
            onChange={e => hande_change(e)}
          >
            <option selected value="0">
              Select a response
            </option>
            {copyRightHolderOptions.map((p, index) => (
              <option value={p.id}>{p.val}</option>
            ))}
          </select>
        </div>
        {showFullForm === true && (
          <>
            <div className="mb-3">
              <label id="claimContentOnCodelinaroOption_label" className="form-label">
                Does your claim involve content on CodeLinaro?
              </label>
              <select
                className="form-control"
                value={form.claimContentOnCodelinaroOption}
                aria-label="Default select example"
                name="claimContentOnCodelinaroOption"
                onChange={e => hande_change(e)}
              >
                <option selected value="0">
                  Select a response
                </option>
                {claimContentOnCodelinaroOptions.map((p, index) => (
                  <option value={p.id}>{p.val}</option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label id="copyRightNature_label" className="form-label">
                Please describe the nature of your copyright ownership or authorization to act on
                the owner's behalf.
              </label>
              <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                id="copyRightNature"
                name="copyRightNature"
                onChange={e => hande_change(e)}
                value={form.copyRightNature || ""}
              ></textarea>
            </div>
            <div className="mb-3">
              <label id="detailDescriptionOfOriginal_label" className="form-label">
                Please provide a detailed description of the original copyrighted work that has
                allegedly been infringed. If possible, include a URL to where it is posted online.
              </label>
              <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                id="detailDescriptionOfOriginal"
                name="detailDescriptionOfOriginal"
                onChange={e => hande_change(e)}
                value={form.detailDescriptionOfOriginal || ""}
              ></textarea>
            </div>
            <div className="mb-3">
              <label id="takeDownUrls_label" className="form-label">
                What files should be taken down? Please provide URLs for each file, or if the entire
                repository, the repository’s URL.
              </label>
              <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                id="takeDownUrls"
                name="takeDownUrls"
                onChange={e => hande_change(e)}
                value={form.takeDownUrls || ""}
              ></textarea>
            </div>
            <div className="mb-3">
              <label id="technoligicalMeasureOption_label" className="form-label">
                Do you claim to have any technological measures in place to control access to your
                copyrighted content? Please see our Complaints about Anti-Circumvention Technology
                if you are unsure.
              </label>
              <select
                className="form-control"
                value={form.technoligicalMeasureOption}
                aria-label="Default select example"
                name="technoligicalMeasureOption"
                onChange={e => hande_change(e)}
              >
                <option selected value="0">
                  Select a response
                </option>
                {technoligicalMeasureOptions.map((p, index) => (
                  <option value={p.id}>{p.val}</option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label id="infringingFiles_label" className="form-label">
                Have you searched for any forks of the allegedly infringing files or repositories?
                Each fork is a distinct repository and must be identified separately if you believe
                it is infringing and wish to have it taken down.
              </label>
              <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                id="infringingFiles"
                name="infringingFiles"
                onChange={e => hande_change(e)}
                value={form.infringingFiles || ""}
              ></textarea>
            </div>
            <div className="mb-3">
              <label id="workUnderOpenSourceOption_label" className="form-label">
                Is the work licensed under an open source license?
              </label>
              <select
                className="form-control"
                value={form.workUnderOpenSourceOption}
                aria-label="Default select example"
                name="workUnderOpenSourceOption"
                onChange={e => hande_change(e)}
              >
                <option selected value="0">
                  Select a response
                </option>
                {workUnderOpenSourceOptions.map((p, index) => (
                  <option value={p.id}>{p.val}</option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label id="solutionForInfringementOption_label" className="form-label">
                What would be the best solution for the alleged infringement?
              </label>
              <select
                className="form-control"
                value={form.solutionForInfringementOption}
                aria-label="Default select example"
                name="solutionForInfringementOption"
                onChange={e => hande_change(e)}
              >
                <option selected value="0">
                  Select a response
                </option>
                {solutionForInfringementOptions.map((p, index) => (
                  <option value={p.id}>{p.val}</option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label id="infringerContactInformation_label" className="form-label">
                Do you have the alleged infringer’s contact information? If so, please provide it.
              </label>
              <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                id="infringerContactInformation"
                name="infringerContactInformation"
                onChange={e => hande_change(e)}
                value={form.infringerContactInformation || ""}
              ></textarea>
            </div>
            <div className="mb-3">
              <input
                type="checkbox"
                placeholder="Your name"
                id="isContentAuthorised"
                name="isContentAuthorised"
                onChange={e => hande_change(e)}
                aria-describedby="emailHelp"
                value={form.isContentAuthorised || false}
              />{" "}
              <span id="isContentAuthorised_label" className="form-label">
                I have a good faith belief that use of the copyrighted materials described above on
                the infringing web pages is not authorized by the copyright owner, or its agent, or
                the law.
              </span>
            </div>
            <div className="mb-3">
              <input
                type="checkbox"
                placeholder="Your name"
                id="isInformationAccurate"
                name="isInformationAccurate"
                onChange={e => hande_change(e)}
                aria-describedby="emailHelp"
                value={form.isInformationAccurate || false}
              />{" "}
              <span id="isInformationAccurate_label" className="form-label">
                I swear, under penalty of perjury, that the information in this notification is
                accurate and that I am the copyright owner, or am authorized to act on behalf of the
                owner, of an exclusive right that is allegedly infringed.
              </span>
            </div>
            <div className="mb-3">
              <input
                type="checkbox"
                placeholder="Your name"
                id="isFaireUseConsidered"
                name="isFaireUseConsidered"
                onChange={e => hande_change(e)}
                aria-describedby="emailHelp"
                value={form.isFaireUseConsidered || false}
              />{" "}
              <span id="isFaireUseConsidered_label" className="form-label">
                I have taken fair use into consideration
              </span>
            </div>
            <div className="mb-3">
              <input
                type="checkbox"
                placeholder="Your name"
                id="hasReadCodelinaroTakedownNotice"
                name="hasReadCodelinaroTakedownNotice"
                onChange={e => hande_change(e)}
                aria-describedby="emailHelp"
                value={form.hasReadCodelinaroTakedownNotice || false}
              />{" "}
              <span id="hasReadCodelinaroTakedownNotice_label" className="form-label">
                I have read and understand CodeLinaro’s Guide to Submitting a DMCA Takedown Notice.
              </span>
            </div>
            <div className="mb-3">
              <label id="communicationAddress_label" className="form-label">
                So that we can get back to you, please provide either your telephone number or
                physical address.
              </label>
              <textarea
                className="form-control"
                rows="5"
                style={{ resize: "none" }}
                id="communicationAddress"
                name="communicationAddress"
                onChange={e => hande_change(e)}
                value={form.communicationAddress || ""}
              ></textarea>
            </div>
            <div className="mb-3">
              <label id="fullName_label" className="form-label">
                Please type your full legal name below to sign this request.
              </label>
              <input
                type="text"
                placeholder="Your full legal name"
                className="form-control"
                id="fullName"
                name="fullName"
                onChange={e => hande_change(e)}
                aria-describedby="emailHelp"
                value={form.fullName || ""}
              />
            </div>
            <form className="row g-3">
              <div className="col-md-2">
                <label for="inputCity" className="form-label">
                  Captcha:
                </label>
                <input
                  type="text"
                  name="inputCode"
                  id="inputCode"
                  value={inputCode}
                  onChange={event => setInputCode(event.target.value)}
                />
              </div>

              <div className="col-md-2">
                <label for="inputZip" className="form-label">
                  &nbsp;
                </label>
                <Captcha onChange={getMarkerCode}></Captcha>
              </div>
            </form>
            <div className="mb-3">See CodeLinaro Policies for more details.</div>
            <button
              type="button"
              onClick={() => submit_form()}
              disabled={!has_changes()}
              className="btn btn-primary"
            >
              Submit
            </button>
            &nbsp;
            <button
              type="button"
              onClick={() => reset_form()}
              disabled={!has_changes()}
              className="btn btn-info"
            >
              Reset
            </button>
          </>
        )}
      </div>

      <Modal
        isOpen={modalInfo.show}
        toggle={() => close_modal()}
        backdrop="static"
        zIndex={1500}
        size="xl"
        centered={true}
      >
        <ModalHeader>{modalInfo.header}</ModalHeader>
        <ModalBody>{modalInfo.content}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={close_modal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
